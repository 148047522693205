.add-form {
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    gap: 15px;
}

.add-form button {
    width: 100%;
}

.add-form textarea{
    height: 100px;
}