.elements-list {
    list-style: none;
    padding: 0;
    margin-top: 20px;
}

.elements-list .table__item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 10px;
}

.elements-list .table__item .item {
    flex: 1;
    padding: 0 10px;
}

.elements-list .table__item button {
    padding: 5px 10px;
    background-color: #d9534f;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.elements-list .table__item button:hover {
    background-color: #c9302c;
}

.elements-list a{
    color: #000;
}

.filter-buttons{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.filter-buttons button.active{
    background-color: #c9302c;
}
