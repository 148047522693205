@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body{
    font-family: "Roboto", sans-serif;
    margin: 0;
    padding: 0;
}

.container{
    width: 1170px;
    max-width: 100%;
    margin: auto;
    padding: 0 20px;
    box-sizing: border-box;
}

.btn{
    padding: 10px 10px;
    border-radius: 5px;
    display: block;
    background: #00d98d;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    text-align: center;
}

p{
    font-size: 16px;
}

button{
    padding: 10px;
    background-color: #00d98d;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

input, textarea{
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
}

h1{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 0;
}

nav{
    padding: 15px 0;
    margin: 0;
}

nav ul{
    list-style: none;
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 0;
    margin: 0;
}

nav ul li a{
    text-decoration: none;
    font-size: 16px;
    color: #000;
}

.data-page a{
    color: #000;
}

.element-detail {
    background-color: #ffffff;
    padding: 20px 20px 10px 20px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.item {
    margin-bottom: 10px;
}

select {
    height: 40px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    box-sizing: border-box;
}

select option {
    padding: 10px;
}


.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup__content {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    position: relative;
    width: 300px;
}

.popup__close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    font-size: 30px;
    padding: 0 7px;
}

.popup ul {
    list-style: none;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 0;
}

.popup li {
    padding: 10px;
    border-radius: 3px;
    cursor: pointer;
    background: #e2e2e2;
    margin-bottom: 5px;
}

.popup .popup__close{
    background: transparent;
    color: #000;
}

.popup h3{
    margin: 0;
    text-align: center;
    font-size: 22px;
    margin-bottom: 10px;
}

.error{
    color: #ff0000;
}

.delete_btn{
    margin-left: 10px;
}

.delete_btn img{
    width: 20px;
    max-width: 100%;
    display: block;
}

.btns__wrapper{
    display: flex;
    gap: 10px;
}

.btns__wrapper button{
    width: 100%;
}

.btns__wrapper button:last-child{
    background: #d9534f;
}

.user-list__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px;
    margin-top: 20px;
}

.user__wrapper{
    display: block;
    text-decoration: none;
    color: #000;
}

.user {
    background: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    height: 100%;
    box-sizing: border-box;
}

.user .item {
    margin-bottom: 5px;
}

.user .item:first-child {
    font-weight: bold;
}

.user-data {
    display: flex;
    flex-direction: column;
}

.user-data .filter-buttons{
    margin-top: 0;
    margin-bottom: 10px;
}

.user-data .filter-checkbox{
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 20px;
}

.user-data .filter-checkbox input{
    display: block;
    margin: 0;
}

.user-data__wrapper {
    width: 100%;
    margin-bottom: 20px;
}

.no-data{
    margin-bottom: 10px;
}

.title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.user-data__container,
.user-data__list {
    background-color: #ffffff;
    padding: 20px 20px 10px 20px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-data__container .item {
    margin-bottom: 10px;
}

.user-data__container .item:first-child {
    font-weight: bold;
}

.user-data__list .user, .user-data__list .table__item {
    background-color: #f0f0f0;
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: block;
}

.user-data__list .table__item a{
    color: #000;
}

.user-data__list .user .item, .user-data__list .table__item .item {
    margin-bottom: 5px;
}

.user-data__list .user .item:first-child, .user-data__list .table__item .item:first-child, .user-data__container .item span {
    font-weight: bold;
}