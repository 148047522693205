.element-detail img{
    max-width: 100%;
    display: block;
    height: 150px;
}

.element-detail input, .element-detail textarea{
    display: flex;
    width: 100%;
    margin-top: 10px;
}

.element-detail textarea{
    height: 200px;
}

.element-detail .choose-image{
    display: flex;
    align-items: center;
    gap: 10px;
}

.element-detail .choose-image button{
    width: 150px;
    margin-top: 10px;
}

