.walltes__container{
    text-align: center;
    padding: 20px 20px 10px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

.walltes__container .item{
    font-size: 16px;
    color: #000;
}