.login-form__container {
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 300px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login-form__field {
    margin-bottom: 10px;
}

.login-form__field label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
}

.login-form__field input {
    width: 100%;
    padding: 8px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    box-sizing: border-box;
}

.login-form__button {
    width: 100%;
}

.login-form__button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.login-form__error {
    color: #ff0000;
    margin-top: 10px;
    margin-bottom: 0;
}